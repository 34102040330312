import { Link } from '@components/common/Link/Link'
import styled from '@mui/material/styles/styled'

export const KeySellingBarWrapper = styled('div', {
  name: 'KeySellingBar',
  slot: 'Wrapper',
})(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.palette.background[1],
  height: 'inherit',

  '.slide': {
    height: 'inherit !important', // Override the height set by the slider
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))
export const StyledKeySellingBarContentLink = styled(Link, {
  name: 'KeySellingBarContentLink',
})(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  justifyContent: 'center',
  alignItems: 'center',
  color: 'initial',
  [theme.breakpoints.up('md')]: {
    marginTop: 0,
  },
}))
export const KeySellingBarTrustPilotWrapper = styled('div', {
  name: 'KeySellingBarTrustPilot',
  slot: 'Wrapper',
})(({}) => ({
  display: 'flex',
  alignItems: 'center',
  minWidth: '300px', // Added to prevent layout shift on load
  height: '100%',
  '.trustpilot-widget': {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
}))
export const KeySellingBarWrapperMobile = styled('div', {
  name: 'KeySellingBar',
  slot: 'WrapperMobile',
})(({ theme }) => ({
  display: 'flex',
  width: '100%',
  height: '100%',
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
  '.slider-list': {
    height: '100%',
  },
}))
export const KeySellingBarWrapperDesktop = styled('div', {
  name: 'KeySellingBar',
  slot: 'WrapperDesktop',
})(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 'inherit',
  },
}))
